import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

interface HeadingProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const StyledHeading = styled.section`
  & h2 {
    font-size: calc(2.5rem + 0.6vw);
    line-height: calc(3rem + 0.6vw);
  }
`

const Heading: React.FC<HeadingProps> = ({ fields }) => (
  <StyledHeading className="container">
    <ParseContent content={fields.description || ''} />
  </StyledHeading>
)

export default Heading
